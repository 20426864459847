import React from 'react';
import { ItemCards } from "../ItemCards/ItemCards.jsx";

export const Home = () => {
  return (
    <div>

      <div>
        <h1>Home page</h1>
      </div>

      <ItemCards/>

    </div>
  )
}