import React from 'react';
import { ItemCardPage } from "../../ItemCardPage/ItemCardPage.jsx";

export const PageCard = () => {
  return (
    <div>

      <ItemCardPage/>

    </div>
  )
}